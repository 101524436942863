import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';
import Layout from '../../components/layout';
import Contact from '../../components/sections/careers/pages/contact';
import Intro from '../../components/sections/careers/pages/intro';
import Predisposition from '../../components/sections/careers/pages/predisposition';
import Provide from '../../components/sections/careers/pages/provide';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import contentIntro from '../../content/careers/pages/backend/intro.json';
import contentPredisposition from '../../content/careers/pages/backend/predisposition.json';
// @ts-ignore
import bannerSrc from '../../images/careers/php/job_offer_php.jpg';

const Backend = () => {
	const classes = useClasses();

	const bannerImage = useMemo(
		() => (
			<StaticImage
				src="../../images/careers/php/job_offer_php.jpg"
				alt=""
				className={classes.image}
				layout="constrained"
				loading="eager"
				placeholder="blurred"
				aria-hidden="true"
			/>
		),
		[]
	);

	return (
		<Layout>
			<SEO title={titles.careers.midBE} description={descriptions.careers.midBE} />

			<Intro content={contentIntro} bannerImage={bannerImage} bannerSrc={bannerSrc} />
			<Predisposition content={contentPredisposition} />
			{/* <Quote content={contentQuote} image={quoteImage} /> */}
			<Provide />
			<Contact />
		</Layout>
	);
};

export default Backend;

const useClasses = makeStyles({
	image: {
		'@media (min-width: 600px)': {
			marginTop: '5.75em',
		},
		'@media (min-width: 1200px)': {
			display: 'none !important',
		},
	},
});
